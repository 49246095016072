import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Col from 'reactstrap/lib/Col';
import { getIsEmoSearchEnabled } from 'client/site-modules/shared/utils/multi-offer/multi-offer';
import { isAddressToVinExcludedState } from 'site-modules/shared/utils/appraisal/appraisal-tabs';
import { AppraisalTabsContainer } from 'site-modules/shared/components/appraisal/appraisal-tabs-container/appraisal-tabs-container';

const EMO_COPY = {
  TITLE: 'See your car’s highest offer',
  SUBTITLE: 'We’ll search for real online offers for your car so you get the best value.',
};

function SellMyCarAppraisalTabsContainerUI({
  isBackgroundStyleTabEnabled,
  stateCode,
  initialStateCode,
  location,
  appraisalTabsCtaColor,
  isMobile,
  isSellCarLanding,
  isSellInCityState,
  isWIDG1125chal1,
}) {
  const isEmoSearchEnabled = getIsEmoSearchEnabled(location);
  const title = isEmoSearchEnabled ? EMO_COPY.TITLE : undefined;
  const subtitle = isEmoSearchEnabled ? EMO_COPY.SUBTITLE : undefined;

  return (
    <Col xs={12} xl={isEmoSearchEnabled && !isSellCarLanding ? 12 : 10} className="px-0">
      <AppraisalTabsContainer
        isBackgroundStyleTabEnabled={isBackgroundStyleTabEnabled}
        isMobile={isMobile}
        isLicensePlateTabDefault
        isSellCar
        initialStateCode={initialStateCode}
        className={classnames({ 'mb-0_5': isBackgroundStyleTabEnabled, 'mt-2 mb-0_5': !isBackgroundStyleTabEnabled })}
        isProfileOfferVinPrepopulationEnabled
        isFullWidth={isBackgroundStyleTabEnabled}
        hasAddressToVinDrawer={!isAddressToVinExcludedState(stateCode)}
        wrapperClassName="pb-3_5"
        title={title}
        subtitle={subtitle}
        isEmoSearchEnabled={isEmoSearchEnabled}
        ctaBtnColor={appraisalTabsCtaColor}
        isSellCarLanding={isSellCarLanding}
        isSellInCityState={isSellInCityState}
        isWIDG1125chal1={isWIDG1125chal1}
      />
    </Col>
  );
}

SellMyCarAppraisalTabsContainerUI.propTypes = {
  isBackgroundStyleTabEnabled: PropTypes.bool,
  stateCode: PropTypes.string,
  initialStateCode: PropTypes.string,
  location: PropTypes.shape({}),
  appraisalTabsCtaColor: PropTypes.string,
  isMobile: PropTypes.bool,
  isSellCarLanding: PropTypes.bool,
  isSellInCityState: PropTypes.bool,
  isWIDG1125chal1: PropTypes.bool,
};

SellMyCarAppraisalTabsContainerUI.defaultProps = {
  isBackgroundStyleTabEnabled: false,
  stateCode: '',
  initialStateCode: '',
  location: {},
  appraisalTabsCtaColor: undefined,
  isMobile: false,
  isSellCarLanding: false,
  isSellInCityState: false,
  isWIDG1125chal1: false,
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  stateCode: get(state, 'visitor.location.stateCode'),
  location: get(state, 'pageContext.location'),
});

export const SellMyCarAppraisalTabsContainer = connect(mapStateToProps)(SellMyCarAppraisalTabsContainerUI);
