import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import Col from 'reactstrap/lib/Col';
import { SellMyCarAppraisalTabsContainer } from 'site-modules/editorial/components/sell-my-car/sell-my-car-appraisal-tabs-container';
import { Breadcrumbs } from 'site-modules/shared/components/breadcrumbs/breadcrumbs';

export const SellCarHeadingSection = ({
  breadcrumbsData,
  title,
  subtitle,
  isAppExtensionPage,
  initialStateCode,
  isSellCarLanding,
  isSellInCityState,
  isWIDG1125chal1,
}) => {
  useEffect(() => {
    document.body.classList.add('bg-cool-gray-90');

    return () => {
      document.body.classList.remove('bg-cool-gray-90');
    };
  }, []);

  return (
    <div className="sell-car-heading-section">
      <Container>
        {!isAppExtensionPage && <Breadcrumbs className="pl-0 mb-2 size-12 text-white" data={breadcrumbsData} />}
        <h1 className="heading-2 mb-0_5 text-white">{title}</h1>
        <Col xs={12} xl={10} className="px-0">
          <p className="size-16 mb-1_5 text-white">{subtitle}</p>
        </Col>
        <SellMyCarAppraisalTabsContainer
          isBackgroundStyleTabEnabled
          appraisalTabsCtaColor="blue-50"
          initialStateCode={initialStateCode}
          isSellCarLanding={isSellCarLanding}
          isSellInCityState={isSellInCityState}
          isWIDG1125chal1={isWIDG1125chal1}
        />
      </Container>
    </div>
  );
};

SellCarHeadingSection.propTypes = {
  breadcrumbsData: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isAppExtensionPage: PropTypes.bool,
  initialStateCode: PropTypes.string,
  isSellCarLanding: PropTypes.bool,
  isSellInCityState: PropTypes.bool,
  isWIDG1125chal1: PropTypes.bool,
};

SellCarHeadingSection.defaultProps = {
  breadcrumbsData: [],
  title: '',
  subtitle: '',
  isAppExtensionPage: false,
  initialStateCode: '',
  isSellCarLanding: false,
  isSellInCityState: false,
  isWIDG1125chal1: false,
};
